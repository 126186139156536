<template>
    <CBox width="100%">
        <!-- <CBox :d="['none', 'none', 'block', 'block']"> -->
        <CBox class="profession-container">
            <!-- Heading -->
            <CBox width="100%" bgColor="white" color="#4A4A4A" borderRadius="8px">
                <CText v-if="type === 'Profession'" font-family="Montserrat" font-size="10px" font-weight="400"
                    line-height="12px" width="100%">
                    Profesi yang cocok untuk kamu
                </CText>
                <CText v-if="type === 'Levelling'" font-family="Montserrat" font-size="10px" font-weight="400"
                    line-height="12px" width="100%">
                    Level kamu
                </CText>
                <CText font-family="Montserrat" font-size="20px" font-weight="700" lineHeight="40px">
                    {{ result['conclusion'].length > 0 ? result["conclusion"][0]["item"] : 'Null' }}
                </CText>
                <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                    {{ result['conclusion'].length > 0 ? result["conclusion"][0]["reason"] : 'Null' }}
                </CText>
                <CBox d="flex" justifyContent="end" mt="1rem">
                    <CBox h="inherit" d="table-cell" verticalAlign="middle">
                        <CText mt="2" fontFamily="Montserrat" color="fontInformation" fontSize="14px">Share to:</CText>
                    </CBox>
                    <CBox d="inline-flex" ml="2">
                        <CBox mr="3" align="center" @click="setClipboard(shareUrl)" style="cursor: pointer">
                            <CImage :src="require('@/assets/icon/copy2.svg')" />
                            <CText mt="1" fontFamily="Montserrat" color="fontInformation" fontSize="9px"
                                fontWeight="400">
                                copy
                            </CText>
                        </CBox>
                        <CBox mr="3" align="center">
                            <ShareNetwork network="linkedin"
                                :title="`Devcode - ${$route.params.id} | ${currentAppType.name}`" :url="shareUrl">
                                <CImage :src="require('@/assets/icon/ic-linkedin.svg')
                                    " />
                                <CText mt="1" fontFamily="Montserrat" color="fontInformation" fontSize="9px"
                                    fontWeight="400">
                                    linkedin
                                </CText>
                            </ShareNetwork>
                        </CBox>
                        <CBox mr="3" align="center">
                            <ShareNetwork network="facebook"
                                :title="`Devcode - ${$route.params.id} | ${currentAppType.name}`" :url="shareUrl"
                                :quote="shareText">
                                <CImage :src="require('@/assets/icon/ic-facebook.svg')
                                    " />
                                <CText mt="1" fontFamily="Montserrat" color="fontInformation" fontSize="9px"
                                    fontWeight="400">
                                    facebook
                                </CText>
                            </ShareNetwork>
                        </CBox>
                        <CBox mr="3" align="center">
                            <ShareNetwork network="twitter" :url="shareUrl" :title="shareText">
                                <CImage :src="require('@/assets/icon/ic-twitter.svg')
                                    " />
                                <CText mt="1" fontFamily="Montserrat" color="fontInformation" fontSize="9px"
                                    fontWeight="400">
                                    twitter
                                </CText>
                            </ShareNetwork>
                        </CBox>
                    </CBox>
                </CBox>
            </CBox>
            <!-- Detail -->
            <CGrid v-if="result['conclusion'].length > 0" :d="['block', 'block', 'grid', 'grid']" width="100%"
                marginTop="1rem" templateColumns="1fr 1fr">
                <CGrid templateColumns="1fr">
                    <CGrid v-for="(item, index) in result['conclusion']" :key="index" templateColumns="40px 1fr"
                        gap="12px" padding="8px"
                        :bgColor="item['key'] === selected['key'] ? ['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][index] : 'white'"
                        borderRadius="8px 0px 0px 8px" @click.native="changeSelected(item['key']);">
                        <CBox :bg="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][index]" d="flex" p="8px"
                            borderRadius="8px">
                            <CImage v-if="['Software Engineer', 'Data Engineer', 'Project Manager'].includes(
                                item['item']
                            )" :src="require('@/assets/icon/icon-' +
                                item['item']
                                    .toLowerCase()
                                    .replace(' ', '-') +
                                '.svg')" :alt="item['item'].toLowerCase()" />
                            <CImage v-else :src="require('@/assets/icon/icon-software-engineer.svg')"
                                :alt="item['item'].toLowerCase()" />
                        </CBox>
                        <CBox d="flex" flexDirection="column">
                            <CText font-family="Montserrat" font-size="12px" font-weight="700" line-height="18px">
                                {{ item["item"] }}
                            </CText>
                            <CGrid templateColumns="1fr 41px" alignItems="center" gap="8px">
                                <CProgress :value="parseInt(item['percentage'].replace('%', ''))"
                                    :color="['green', 'yellow', 'red', 'blue', 'indigo'][index]" size="sm" />
                                <CText font-family="Inter" font-size="16px" font-weight="700" line-height="19.36px">
                                    {{ item["percentage"] }}
                                </CText>
                            </CGrid>
                        </CBox>
                    </CGrid>
                </CGrid>
                <CBox :bgColor="['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][selected['key'] - 1]"
                    :borderRight="'2px solid ' + ['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['key'] - 1]"
                    borderRadius="0px 8px 8px 0px" padding="8px 16px 8px 16px">
                    <CText font-family="Montserrat" font-size="25px" font-weight="700" line-height="30px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['key'] - 1]">{{
                            selected['percentage'] }}</CText>
                    <CText font-family="Montserrat" font-size="16px" font-weight="700" line-height="26px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['key'] - 1]">{{
                            selected['item'] }}</CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        {{ selected['reason'] }}
                    </CText>
                </CBox>
            </CGrid>
            <CGrid v-else :d="['block', 'block', 'grid', 'grid']" width="100%" marginTop="1rem"
                templateColumns="1fr 1fr">
                <CGrid templateColumns="1fr">
                    <CGrid templateColumns="40px 1fr" gap="12px" padding="8px" bgColor="#F0FFF2"
                        borderRadius="8px 0px 0px 8px">
                        <CBox bg="#0CA82B" d="flex" p="8px" borderRadius="8px">
                            <CImage :src="require('@/assets/icon/icon-software-engineer.svg')" alt="default-icon" />
                        </CBox>
                        <CBox d="flex" flexDirection="column">
                            <CText font-family="Montserrat" font-size="12px" font-weight="700" line-height="18px">
                                Default
                            </CText>
                            <CGrid templateColumns="1fr 41px" alignItems="center" gap="8px">
                                <CProgress value="50" color="green" size="sm" />
                                <CText font-family="Inter" font-size="16px" font-weight="700" line-height="19.36px">
                                    50%
                                </CText>
                            </CGrid>
                        </CBox>
                    </CGrid>
                </CGrid>
                <CBox bgColor="#F0FFF2" borderRight="2px solid #0CA82B" borderRadius="0px 8px 8px 0px"
                    padding="8px 16px 8px 16px">
                    <CText font-family="Montserrat" font-size="25px" font-weight="700" line-height="30px"
                        color="#0CA82B">50%</CText>
                    <CText font-family="Montserrat" font-size="16px" font-weight="700" line-height="26px"
                        color="#0CA82B">Default</CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde velit quos fuga exercitationem
                        recusandae impedit odio repudiandae ipsum earum at repellat, voluptatibus facere, laudantium
                        blanditiis. Nam corrupti id natus fuga?
                    </CText>
                </CBox>
            </CGrid>
        </CBox>
        <!-- </CBox> -->
    </CBox>
</template>

<script>
import { CBox, CGrid, CText, CProgress, CImage } from '@chakra-ui/vue';
import config from "@/ConfigProvider";

export default {
    components: {
        CBox,
        CGrid,
        CText,
        CProgress,
        CImage
    },
    props: {
        result: {
            type: Object
        },
        type: {
            type: String
        },
        currentAppType: {
            type: Object
        }
    },
    data() {
        return {
            selected: this.result["conclusion"][0] ?? {},
            shareUrl: config.value("DEVCODE_WEB_URL") + "/job/share/" + this.$route.params.id
        }
    },
    computed: {
        shareText() {
            let text = "Hasil tes "
            if (this.type === "Profession") {
                text += "menunjukkan profesi saya cocok sebagai "
            }

            text += this.result["conclusion"][0].item
            return text
        }
    },
    methods: {
        changeSelected(key) {
            this.selected = this.result["conclusion"].find(i => i["key"] === key)
        },
        setClipboard(value) {
            var tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            this.$toast({
                title: "Copied",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: false,
            });
        },
    }
}
</script>

<style>
.profession-container {
    width: 100%;
}
</style>
