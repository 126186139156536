<template>
  <CBox>
    <CBox
      bgImage="url('/img/bg-certificate-fitacademy.png')"
      bgSize="100% 484px"
      bgRepeat="no-repeat"
      minH="480px"
    >
      <CGrid templateColumns="1fr 1fr" :gap="4">
        <CBox textAlign="center">
          <CBox h="355px">
            <CText
              ps="20%"
              pt="40px"
              w="310px"
              fontFamily="Poppins"
              fontStyle="normal"
              textAlign="left"
              color="white"
              fontWeight="bold"
              fontSize="34px"
              >Sertifikat Fitacademy Logic Test 2024</CText
            >
          </CBox>
          <CBox mt="22px" align="center" pr="10%">
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="bold"
              fontSize="10px"
              mb="5px"
              >ID {{ currentTest.id }}</CText
            >
            <VueQr
              backgroundColor="#F4F4F4"
              :text="baseUrl + '/job/' + currentTest.id"
              :size="50"
              :margin="0"
            />
          </CBox>
        </CBox>
        <CBox textAlign="left" ms="10px">
          <CBox
            bgImage="url('/img/fitacademy-logo.png')"
            color="fontMain"
            bgSize="80% 100%"
            bgRepeat="no-repeat"
            minH="80px"
            mt="28px"
            mx="auto"
            :d="['none', 'none', 'block', 'block']"
          ></CBox>
          <CText
            mt="5px"
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="bolder"
            color="fontMain"
            >Sertifikat diberikan kepada</CText
          >
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="700"
            :fontSize="isSmallerFontSize ? '21px' : '32px'"
            verticalAlign="middle"
            >{{ currentTest.user.emp_full_name }}</CText
          >
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="12px"
            color="fontMain"
            >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
            mengikuti<br />Fitacademy Logic Test 2024 dari Fitacademy dan
            Devcode.</CText
          >
          <CBox align="center" w="400px" pr="56px">
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="24px"
              color="#EB8A3A"
              fontWeight="bold"
              >LULUS</CText
            >
          </CBox>
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="12px"
            color="fontMain"
            >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang
            ada dengan detail :</CText
          >
          <CBox mt="20px">
            <CGrid templateColumns="1fr 2fr" :gap="3" w="350px">
              <CBox
                w="100%"
                h="100%"
                :border="showBorder ? '1px' : '0'"
                borderRadius="6px"
                align="center"
                borderColor="bgMain"
              >
                <CText
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="11px"
                  color="fontMain"
                  fontWeight="bolder"
                  pt="10px"
                  lineHeight="10px"
                  >COMPLETION TIME</CText
                >
                <CText
                  pb="10px"
                  pt="10px"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="32px"
                  fontWeight="bolder"
                  color="#EB8A3A"
                  lineHeight="31px"
                  >{{ formatResponseTime(currentTest.total_duration)
                  }}<span style="font-size: 12px">mnt</span></CText
                >
              </CBox>
              <CBox>
                <CBox
                  w="100%"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  align="center"
                  borderColor="bgMain"
                >
                  <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                    <CText
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontSize="12px"
                      color="fontMain"
                      fontWeight="bolder"
                      >PERINGKAT {{ currentTest.rank }}</CText
                    >
                    <CText
                      pl="4px"
                      pt="2px"
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontSize="10px"
                      color="fontMain"
                      fontWeight="300"
                      >/
                      {{ currentTest.total_participants }}
                      peserta</CText
                    >
                  </CBox>
                </CBox>
                <CBox pt="5px">
                  <CSimpleGrid :columns="2" :spacing="2">
                    <CBox
                      d="flex"
                      :border="showBorder ? '1px' : '0'"
                      borderRadius="6px"
                      borderColor="bgMain"
                      w="100%"
                      h="38px"
                    >
                      <CBox mx="auto" h="inherit">
                        <CStack
                          d="table-cell"
                          direction="row"
                          h="inherit"
                          verticalAlign="middle"
                        >
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="14px"
                              color="fontMain"
                              fontWeight="bolder"
                              >PASSED</CText
                            >
                          </CBox>
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            pl="10px"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="22px"
                              color="passedColor"
                              fontWeight="bolder"
                              >{{ currentTest.result.passed }}</CText
                            >
                          </CBox>
                        </CStack>
                      </CBox>
                    </CBox>
                    <CBox
                      d="flex"
                      :border="showBorder ? '1px' : '0'"
                      borderRadius="6px"
                      borderColor="bgMain"
                      w="100%"
                      h="38px"
                    >
                      <CBox mx="auto" h="inherit">
                        <CStack
                          d="table-cell"
                          direction="row"
                          h="inherit"
                          verticalAlign="middle"
                        >
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="14px"
                              color="fontMain"
                              fontWeight="bolder"
                              >SCORE</CText
                            >
                          </CBox>
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            pl="10px"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="22px"
                              color="passedColor"
                              fontWeight="bolder"
                              >{{ currentTest.total_point }}</CText
                            >
                          </CBox>
                        </CStack>
                      </CBox>
                    </CBox>
                  </CSimpleGrid>
                </CBox>
              </CBox>
            </CGrid>
          </CBox>
          <CBox h="10px" />
          <CBox d="flex" justifyContent="center">
            <CBox w="165px" align="center">
              <CBox h="40px" w="80px" mx="auto" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="12px"
                color="fontMain"
                fontWeight="800"
                >Istofani Api Diany</CText
              >
              <CDivider borderColor="borderBox" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="10px"
                color="fontMain"
                fontWeight="bolder"
                >CEO Fitacademy</CText
              >
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
    </CBox>

    <!-- mobile layout -->
    <CBox
      :d="['block', 'block', 'none', 'none']"
      border="1px solid"
      borderColor="bgMain"
      p="1em"
      borderRadius="5px"
      align="center"
    >
      <CText
        mt="10px"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="bolder"
        color="fontMain"
        >Sertifikat Programming Competition<br />ICOM 2022 x GetHired</CText
      >
      <CText
        mt="20px"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="14px"
        fontWeight="bolder"
        color="fontMain"
        >Sertifikat diberikan kepada</CText
      >
      <CText
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="18px"
        fontWeight="bolder"
        color="#D9019C"
        d="table-cell"
        verticalAlign="middle"
        >{{ currentTest.user.emp_full_name }}</CText
      >
      <CText
        whiteSpace="wrap"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="12px"
        color="fontMain"
        >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
        mengikuti<br />ICOM 2022 dengan tema {{ currentAppType.name }}.</CText
      >
      <CBox align="center">
        <CText
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="30px"
          fontWeight="bold"
          color="fontMain"
          py="0.5em"
          >LULUS</CText
        >
      </CBox>
      <CText
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="12px"
        color="fontMain"
        >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang ada
        dengan detail :</CText
      >
      <CBox mt="5px">
        <CGrid templateColumns="12fr" :gap="3">
          <CBox
            w="100%"
            h="100%"
            :border="showBorder ? '1px' : '0'"
            borderRadius="6px"
            align="center"
            borderColor="bgMain"
          >
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="11px"
              color="fontMain"
              fontWeight="bolder"
              pt="10px"
              lineHeight="10px"
              >COMPLETION TIME</CText
            >
            <CText
              pb="10px"
              pt="10px"
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="32px"
              color="#D9019C"
              fontWeight="bolder"
              lineHeight="31px"
              >{{ formatResponseTime(currentTest.total_duration) }}
              <span style="font-size: 12px">mnt</span>
            </CText>
          </CBox>
          <CBox>
            <CBox
              w="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                <CText
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="12px"
                  color="fontMain"
                  fontWeight="bolder"
                  >PERINGKAT {{ currentTest.rank }}</CText
                >
                <CText
                  pl="4px"
                  pt="2px"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="10px"
                  color="fontMain"
                  fontWeight="300"
                  >dari
                  {{ currentTest.total_participants }}
                  peserta</CText
                >
              </CBox>
            </CBox>
            <CBox pt="5px">
              <CSimpleGrid :columns="2" :spacing="2">
                <CBox
                  d="flex"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  borderColor="bgMain"
                  w="100%"
                  h="38px"
                >
                  <CBox mx="auto" h="inherit">
                    <CStack
                      d="table-cell"
                      direction="row"
                      h="inherit"
                      verticalAlign="middle"
                    >
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="14px"
                          color="fontMain"
                          fontWeight="bolder"
                          >PASSED</CText
                        >
                      </CBox>
                      <CBox
                        d="table-cell"
                        verticalAlign="middle"
                        h="inherit"
                        pl="10px"
                      >
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="22px"
                          color="passedColor"
                          fontWeight="bolder"
                          >{{ currentTest.result.passed }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CBox>
                </CBox>
                <CBox
                  d="flex"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  borderColor="bgMain"
                  w="100%"
                  h="38px"
                >
                  <CBox mx="auto" h="inherit">
                    <CStack
                      d="table-cell"
                      direction="row"
                      h="inherit"
                      verticalAlign="middle"
                    >
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="14px"
                          color="fontMain"
                          fontWeight="bolder"
                          >SCORE</CText
                        >
                      </CBox>
                      <CBox
                        d="table-cell"
                        verticalAlign="middle"
                        h="inherit"
                        pl="10px"
                      >
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="22px"
                          color="passedColor"
                          fontWeight="bolder"
                          >{{ currentTest.total_point }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CBox>
                </CBox>
              </CSimpleGrid>
            </CBox>
          </CBox>
        </CGrid>
      </CBox>
    </CBox>
  </CBox>
</template>
<script>
import config from "@/ConfigProvider";
import { CText } from "@chakra-ui/vue";
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
    CText,
  },
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      isSmallerFontSize: false,
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
  },
  mounted() {
    this.formatNameFontSize();
  },
  methods: {
    formatResponseTime(value) {
      if (value) {
        // Convert milliseconds to minutes with one decimal place
        let minutes = (Math.round((value / 1000 / 60) * 10) / 10).toFixed(1);
        return `${minutes}`;
      }
      return null;
    },
    formatNameFontSize() {
      if (this.currentTest.user.emp_full_name.length > 20) {
        this.isSmallerFontSize = true;
      } else {
        this.isSmallerFontSize = false;
      }
    },
  },
};
</script>
