var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[_c('CBox',{attrs:{"bg":"white","borderRadius":"5px","boxShadow":"0px 4px 10px rgba(0, 0, 0, 0.1)"}},[_c('CFlex',{attrs:{"py":"20px","mx":"20px","alignItems":"center","justifyContent":"start"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"600","color":"fontMain"}},[_vm._v(" Statistik")])],1),_c('CFlex',{attrs:{"px":"20px","alignItems":"center","mb":"12px"}},[_c('CBox',{attrs:{"overflow":"auto","pb":"8px","d":"flex"}},[_c('CBox',{attrs:{"flex":"0 0 auto","d":"flex","alignItems":"center","px":"14px","py":"2px","borderRadius":"5px","fontSize":"14px","border":"1px solid","borderColor":_vm.currentLeaderboardsActiveFilter.type !== 'club'
                        ? 'activeMenu'
                        : 'bgMain',"fontWeight":"400","cursor":"pointer","mr":"6px"},nativeOn:{"click":function($event){return _vm.resetLeaderboardHandler()}}},[_c('CText',{attrs:{"color":_vm.currentLeaderboardsActiveFilter.type !== 'club'
                        ? 'activeMenu'
                        : 'fontMain'}},[_vm._v("All")])],1),_vm._l((_vm.currentLeaderboardsFilters),function(v,i){return _c('CBox',{key:i,attrs:{"flex":"0 0 auto","d":"flex","alignItems":"center","px":"14px","py":"2px","borderRadius":"5px","fontSize":"14px","border":"1px solid","borderColor":_vm.currentLeaderboardsActiveFilter.id === v.id
                        ? 'activeMenu'
                        : 'bgMain',"fontWeight":"400","cursor":"pointer","mr":"6px"},nativeOn:{"click":function($event){return _vm.filterHandler({
                                type: 'club',
                                id: v.id,
                                name: v.name,
                                slug: v.slug,
                            })}}},[_c('CText',{attrs:{"color":_vm.currentLeaderboardsActiveFilter.id === v.id
                        ? 'activeMenu'
                        : 'fontMain'}},[_vm._v(_vm._s(_vm.truncateText(v.name, 15)))])],1)})],2)],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"center"}},[_c('VueApexChart',{staticClass:"c-chart",attrs:{"type":"bar","series":_vm.series,"options":_vm.chartOptions}})],1),_c('CBox',{attrs:{"m":[
            '20px 20px 13px 20px',
            '20px 20px 13px 20px',
            '20px 20px 20px 20px',
            '20px 20px 20px 20px',
        ],"pb":"30px","align":"center"}},[_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","color":"activeMenu"},nativeOn:{"click":function($event){return _vm.showmoreHandler()}}},[_vm._v("Show More ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }