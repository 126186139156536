import { render, staticRenderFns } from "./DetailJob.vue?vue&type=template&id=0d471458"
import script from "./DetailJob.vue?vue&type=script&lang=js"
export * from "./DetailJob.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CDivider: require('@chakra-ui/vue').CDivider, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CIcon: require('@chakra-ui/vue').CIcon, CFlex: require('@chakra-ui/vue').CFlex, CStack: require('@chakra-ui/vue').CStack, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid})
