<!-- eslint-disable prettier/prettier -->
<template>
  <CBox>
    <Loading :active="isLoading" />
    <CBox :mt="['13px', '13px', '30px', '30px']">
      <CGrid
        :templateColumns="
          currentAppType.view_type == 'RECRUITMENT'
            ? '12fr'
            : ['12fr', '12fr', '8fr 4fr', '8fr 4fr']
        "
        :gap="[3, 3, 6, 6]"
      >
        <CBox v-if="!isLoading">
          <AppDescription
            :currentAppType="currentAppType"
            :useMoreDesc="true"
          />
          <CBox
            v-if="checkEmpObject(currentTest.result) || currentTest?.is_evaluate"
            bg="white"
            :mt="['12px', '12px', '20px', '20px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox pt="20px" pb="10px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="bold"
                color="fontMain"
                >RESULT ID {{ currentTest.id }}</CText
              >
            </CBox>
            <CDivider />
            <CBox pb="20px" mx="20px" v-if="!checkEmpObject(currentTest.user)">
              <CGrid
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="10px"
                mt="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >NAME</CText
                >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="600"
                  color="fontMain"
                  >{{ currentTest.user.emp_full_name }}</CText
                >
              </CGrid>
              <CGrid
                v-if="currentAppType.type === 'FE'"
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >SITE NAME</CText
                >
                <CBox
                  d="flex"
                  class="pointer"
                  @click.native="openSite(currentTest.site_url)"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    >{{
                      currentTest.site_url
                        ? currentTest.site_url
                        : currentTest.site_name
                    }}</CText
                  >
                  <CImage
                    pl="5px"
                    :src="require('@/assets/icon/ic-link.svg')"
                  />
                </CBox>
              </CGrid>
              <CBox align="center" my="100px">
                <CImage :src="require('@/assets/img/aircraft.png')" mx="auto" />
                <CBox mt="50px" align="center">
                  <CBox d="flex" direction="row" justifyContent="center">
                    <CImage
                      :src="require('@/assets/icon/ic-loading.gif')"
                      w="40px"
                    />
                    <CText
                      pt="10px"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="16px"
                      fontWeight="500"
                      color="fontMain"
                    >
                      Testing is in {{ currentTest.job_status }}, You may close this page. We will send notification on your email.
                    </CText>
                  </CBox>
                  <CBox d="flex" direction="row" justifyContent="center">
                    <CText
                      pt="10px"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="500"
                      color="fontMain"
                      v-if="!checkEmpObject(currentTest.job_progress)"
                    >
                      {{ currentTest.job_progress.currentJob }} /
                      {{ currentTest.job_progress.totalJob }}<br />
                      {{ currentTest.job_progress.messageJob }}
                    </CText>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
          </CBox>
          <CBox
            v-if="!checkEmpObject(currentTest.result) && !currentTest?.is_evaluate"
            bg="white"
            :mt="['13px', '13px', '20px', '20px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox
              d="flex"
              justifyContent="space-between"
              pt="20px"
              pb="10px"
              mx="20px"
            >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="bold"
                color="fontMain"
                >RESULT ID {{ currentTest.id }}</CText
              >
              <CBox d="flex">
                <CBox
                  mr="4"
                  d="flex"
                  alignItems="center"
                  cursor="pointer"
                  @click.native="postResultLike"
                >
                  <ButtonIcon
                    :icon="
                      isLikedByUser
                        ? 'ic-heart-solid.svg'
                        : 'ic-heart-regular.svg'
                    "
                  />
                  <CText
                    ml="1"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="main"
                    >{{ currentLikes.length }}</CText
                  >
                  <CText
                    :d="['none', 'none', 'inline', 'inline']"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="main"
                    >&nbsp;{{
                      `${currentLikes.length > 1 ? "likes" : "like"}`
                    }}</CText
                  >
                </CBox>
                <CBox
                  mr="4"
                  d="flex"
                  alignItems="center"
                  cursor="pointer"
                  @click.native="showCommentsModal"
                >
                  <ButtonIcon icon="ic-comment-regular.svg" />
                  <CText
                    ml="1"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="main"
                    >{{ `${currentComments.length}` }}</CText
                  >
                  <CText
                    :d="['none', 'none', 'inline', 'inline']"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="main"
                    >&nbsp;{{
                      `${currentComments.length > 1 ? "comments" : "comment"}`
                    }}</CText
                  >
                </CBox>
                <CBox d="flex" alignItems="center" cursor="pointer">
                  <ButtonIcon
                    icon="copy.svg"
                    @click.native="setClipboard(shareUrl)"
                  />
                </CBox>
              </CBox>
            </CBox>
            <CDivider />
            <CBox pb="20px" mx="20px">
              <CGrid
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="10px"
                mt="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >NAME</CText
                >
                <CBox
                  d="flex"
                  class="pointer"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: { emp_id: currentTest.user.emp_id },
                    })
                  "
                >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    >{{ currentTest.user.emp_full_name }}</CText
                  >
                </CBox>
              </CGrid>
              <CGrid
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="20px"
                mt="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >HASHTAGS</CText
                >
                <CBox v-if="currentTest.hashtags.length > 0" d="inline-flex">
                  <CText
                    v-for="(item, index) in currentTest.hashtags"
                    :key="index"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    cursor="pointer"
                    @click.native="
                      $router.push({
                        name: 'Leaderboard',
                        params: {
                          slug: currentAppType.slug,
                          type: 'hashtag',
                          type_id: item.slug,
                        },
                        query: {
                          hashtag: item.name,
                        },
                      })
                    "
                    >#{{ item.name
                    }}<span
                      v-if="
                        currentTest.hashtags.length > 1 &&
                        currentTest.hashtags.length !== index + 1
                      "
                      >,&nbsp;</span
                    ></CText
                  >
                </CBox>
                <CBox v-else>
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                    cursor="pointer"
                    >No hashtags found</CText
                  >
                </CBox>
              </CGrid>
              <CGrid
                v-if="currentAppType.type === 'FE'"
                templateColumns="1fr 3fr"
                :gap="6"
                w="100%"
                mb="20px"
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  color="fontInformation"
                  >SITE NAME</CText
                >
                <CBox
                  d="flex"
                  class="pointer"
                  @click.native="openSite(currentTest.site_url)"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="600"
                    color="activeMenu"
                    >{{
                      currentTest.site_url
                        ? currentTest.site_url
                        : currentTest.site_name
                    }}</CText
                  >
                  <CImage
                    pl="5px"
                    :src="require('@/assets/icon/ic-link.svg')"
                  />
                </CBox>
              </CGrid>              
              <CBox v-if="!isLoading">
                <CBox v-if="currentAppType.view_type == 'RECRUITMENT'">
                  <CertificateAspect />
                </CBox>
                <CBox v-else>
                  <CBox
                    v-if="
                      currentTest.passed_score >= 70 &&
                      !['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)
                    "
                  >
                    <CertificateNiagahoster
                      v-if="currentAppType.certificate_type === 'fitacademy'"
                    />
                    <CertificateNiagahoster
                      v-if="currentAppType.certificate_type === 'niagahoster'"
                    />
                    <CertificateCompetition
                      v-else-if="currentAppType.certificate_type === 'uad'"
                    />
                    <!-- </section>
                    </vue-html2pdf> -->
                  </CBox>
                  <CBox
                    v-if="
                      Math.ceil(currentAppType.settings.max_score * 10 * 0.8) <=
                        currentTest.result.passed * 10 &&
                      currentAppType.type === 'LOGIC'
                    "
                  >
                    <CertificateFitacademy
                      v-if="currentAppType.certificate_type === 'fitacademy'"
                    />
                    <CertificateNiagahoster
                      v-else-if="
                        currentAppType.certificate_type === 'niagahoster'
                      "
                    />
                    <CertificateCompetition
                      v-else-if="currentAppType.certificate_type === 'uad'"
                    />
                    <Certificate v-else />
                  </CBox>
                  <CertificateProfession v-if="currentTest.is_review_result" :result="currentTest.result.assessment_result" :type="currentTest.review_job_target" :currentAppType="currentAppType"/>
                  <Certificate v-else />
                  <CBox
                    w="100%"
                    my="30px"
                    v-if="currentTest.passed_score >= 70"
                  >
                    <CBox w="100%">
                      <CBox w="100%" align="right" mb="3">
                        <CBox v-if="!currentTest.is_review_result" d="inline-flex">
                          <CButton
                            v-if="!isUserResult()"
                            variant="outline"
                            backgroundColor="#fff"
                            mr="2"
                            color="main"
                            borderColor="main"
                            fontSize="14px"
                            @click="
                              $router.push({
                                name: 'Report Result',
                                params: { job_id: $route.params.id },
                              })
                            "
                          >
                            <CImage
                              :src="
                                require('@/assets/icon/ic-flag-regular.svg')
                              "
                              w="18px"
                              h="18px"
                              mr="2"
                            />
                            Flag Result
                          </CButton>
                          <CBox
                            :d="['none', 'none', 'block', 'block']"
                            pb="1 0px"
                          >
                            <ButtonBlue
                              v-if="
                                showDownloadAndDescription &&
                                currentAppType.certificate_type !== NULL
                              "
                              label="Download Certificate"
                              @click.native="generateReport()"
                            />
                          </CBox>
                        </CBox>
                        <!-- <CBox v-if="currentTest.is_review_result" width="100%" d="flex" justifyContent="end">
                          <CButton
                              backgroundColor="#fff"
                              mr="2"
                              color="#0278B7"
                              borderColor="#0278B7"
                              variant="outline"
                          >
                              <CImage
                                  :src="require(`@/assets/icon/icon-chat-blue.svg`)"
                                  w="16px"
                                  mr="5px"
                              />
                              <CText
                                  font-family="Montserrat"
                                  font-size="16px"
                                  font-weight="600"
                                  line-height="26px"
                              >Help Us Improve</CText>
                          </CButton>
                          <CButton
                              backgroundColor="#0278b7"
                              color="#fff"
                              variant="solid"
                              _hover="main"
                          >
                              <CImage
                                  :src="require(`@/assets/icon/icon-share-white.svg`)"
                                  w="16px"
                                  mr="5px"
                              />
                              <CText
                                  font-family="Montserrat"
                                  font-size="16px"
                                  font-weight="600"
                                  line-height="26px"
                              >Share</CText>
                          </CButton>
                        </CBox> -->
                      </CBox>
                      <!-- info for download certificate on mobile -->
                      <CFlex
                        v-if="
                          showDownloadAndDescription &&
                          currentAppType.certificate_type !== NULL &&
                          !currentTest.is_review_result
                        "
                        :d="['flex', 'flex', 'none', 'none']"
                        align="center"
                        bg="bgTestList"
                        py="3"
                        px="4"
                        mb="1em"
                        borderRadius="6px"
                      >
                        <CIcon
                          name="info"
                          color="activeMenu"
                          size="24px"
                          mr="2"
                        />
                        <CText color="activeMenu" fontSize="14px">
                          Fitur <strong>Download Certificate</strong> tidak
                          tersedia di Tampilan Mobile
                        </CText>
                      </CFlex>

                      <CBox
                        v-if="showDownloadAndDescription && !currentTest.is_review_result"
                        d="inline-flex"
                        justifyContent="space-between"
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                        borderRadius="6px"
                        p="4"
                      >
                        <CImage
                          :src="require('@/assets/icon/medal.svg')"
                          w="10vh"
                          h="10vh"
                        />
                        <CBox ml="2">
                          <CText
                            fontFamily="Montserrat"
                            fontStyle="normal"
                            fontSize="14px"
                            fontWeight="400"
                            color="#4A4A4A"
                          >
                            {{ passedInfo }}
                          </CText>
                          <CBox w="100%" align="right" mt="4">
                            <CBox h="40px" d="inline-flex">
                              <CBox
                                h="inherit"
                                d="table-cell"
                                verticalAlign="middle"
                              >
                                <CText
                                  mt="2"
                                  fontFamily="Montserrat"
                                  color="fontInformation"
                                  fontSize="14px"
                                  >Share to:</CText
                                >
                              </CBox>
                              <CBox d="inline-flex" ml="2">
                                <CBox
                                  mr="3"
                                  align="center"
                                  @click="setClipboard(shareUrl)"
                                  style="cursor: pointer"
                                >
                                  <CImage
                                    :src="require('@/assets/icon/copy2.svg')"
                                  />
                                  <CText
                                    mt="1"
                                    fontFamily="Montserrat"
                                    color="fontInformation"
                                    fontSize="9px"
                                    fontWeight="400"
                                  >
                                    copy
                                  </CText>
                                </CBox>
                                <CBox mr="3" align="center">
                                  <ShareNetwork
                                    network="linkedin"
                                    :title="`Devcode - ${$route.params.id} | ${currentAppType.name}`"
                                    :url="shareUrl"
                                  >
                                    <CImage
                                      :src="
                                        require('@/assets/icon/ic-linkedin.svg')
                                      "
                                    />
                                    <CText
                                      mt="1"
                                      fontFamily="Montserrat"
                                      color="fontInformation"
                                      fontSize="9px"
                                      fontWeight="400"
                                    >
                                      linkedin
                                    </CText>
                                  </ShareNetwork>
                                </CBox>
                                <CBox mr="3" align="center">
                                  <ShareNetwork
                                    network="facebook"
                                    :title="`Devcode - ${$route.params.id} | ${currentAppType.name}`"
                                    :url="shareUrl"
                                    :quote="shareText"
                                  >
                                    <CImage
                                      :src="
                                        require('@/assets/icon/ic-facebook.svg')
                                      "
                                    />
                                    <CText
                                      mt="1"
                                      fontFamily="Montserrat"
                                      color="fontInformation"
                                      fontSize="9px"
                                      fontWeight="400"
                                    >
                                      facebook
                                    </CText>
                                  </ShareNetwork>
                                </CBox>
                                <CBox mr="3" align="center">
                                  <ShareNetwork
                                    network="twitter"
                                    :url="shareUrl"
                                    :title="shareText"
                                  >
                                    <CImage
                                      :src="
                                        require('@/assets/icon/ic-twitter.svg')
                                      "
                                    />
                                    <CText
                                      mt="1"
                                      fontFamily="Montserrat"
                                      color="fontInformation"
                                      fontSize="9px"
                                      fontWeight="400"
                                    >
                                      twitter
                                    </CText>
                                  </ShareNetwork>
                                </CBox>
                              </CBox>
                            </CBox>
                          </CBox>
                        </CBox>
                      </CBox>
                    </CBox>
                  </CBox>
                  <CBox
                    w="100%"
                    my="30px"
                    v-if="currentTest.is_review_result"
                  >
                    <CBox w="100%">
                      <CBox w="100%" align="right" mb="3">
                        <CBox width="100%" d="flex" justifyContent="end">
                          <CButton
                              backgroundColor="#fff"
                              mr="2"
                              color="#0278B7"
                              borderColor="#0278B7"
                              variant="outline"
                          >
                              <CImage
                                  :src="require(`@/assets/icon/icon-chat-blue.svg`)"
                                  w="16px"
                                  mr="5px"
                              />
                              <CText
                                  font-family="Montserrat"
                                  font-size="16px"
                                  font-weight="600"
                                  line-height="26px"
                              >Help Us Improve</CText>
                          </CButton>
                      </CBox>
                      </CBox>
                    </CBox>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
            <CDivider borderSize="2px" borderColor="borderBox" />
            <CBox pt="20px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Result Detail</CText
              >
            </CBox>
            <CBox v-if="currentTest.is_review_result" pb="30px" mx="20px">
            </CBox>
            <CBox v-if="!currentTest.is_review_result" pb="30px" mx="20px"
                d="flex"
                class="pointer"
                @click.native="
                  $router.push({
                    name: 'Detail Log',
                    params: {
                      id: currentTest.id,
                    },
                  })
                "
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="600"
                  color="activeMenu"
                  >DETAIL LOG</CText
                >
                <CImage
                  pl="5px"
                  :src="require('@/assets/icon/ic-link.svg')"
                />
              </CBox>
            <CBox v-if="!currentTest.is_review_result">
              <CBox h="20px" bgColor="bgTestList" />
              <CBox
                px="20px"
                bgColor="bgTestList"
                v-for="(v, i) in currentTest.result_test"
                :key="i"
              >
                <CBox py="20px">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="700"
                    color="fontMain"
                    >{{ v.title ? v.title : v.name }}</CText
                  >
                </CBox>
                <CBox
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor="borderTest"
                  bg="white"
                  w="100%"
                >
                  <CBox
                    v-for="(v2, i2) in v.tests ? v.tests : v.result"
                    :key="i2"
                    px="20px"
                    py="10px"
                    borderBottomWidth="1px"
                    borderBottomColor="borderTest"
                  >
                    <CBox d="flex" justifyContent="space-between">
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="700"
                        color="fontMain"
                        v-html="v2.suites_str"
                      ></CText>
                      <CBox w="10px" />
                      <CBox v-if="v2.state == 'passed'">
                        <CBox
                          d="flex"
                          bg="passedColor"
                          borderRadius="full"
                          w="100px"
                          h="25px"
                          justifyContent="center"
                          minW="110px"
                        >
                          <CStack
                            d="table-cell"
                            direction="row"
                            h="inherit"
                            verticalAlign="middle"
                          >
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                            >
                              <CIcon name="check" color="white" size="10px" />
                            </CBox>
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              pl="5px"
                            >
                              <CText
                                lineHeight="25px"
                                fontFamily="Montserrat"
                                fontStyle="normal"
                                fontSize="12px"
                                color="white"
                                >Passed</CText
                              >
                            </CBox>
                          </CStack>
                        </CBox>
                        <CText
                          v-if="v2.duration && !isTeamMode()"
                          mt="2"
                          lineHeight="11px"
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="11px"
                          color="grey"
                        >
                          {{
                            currentAppType.type === "LOGIC"
                              ? v2.durationText
                              : `${formatAppResponse(v2.duration)}ms`
                          }}
                        </CText>
                      </CBox>
                      <CBox v-else>
                        <CBox
                          d="flex"
                          bg="failedColor"
                          borderRadius="full"
                          w="100px"
                          h="25px"
                          justifyContent="center"
                          minW="110px"
                        >
                          <CStack
                            d="table-cell"
                            direction="row"
                            h="inherit"
                            verticalAlign="middle"
                          >
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                            >
                              <CIcon name="close" color="white" size="10px" />
                            </CBox>
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              pl="10px"
                            >
                              <CText
                                lineHeight="25px"
                                fontFamily="Montserrat"
                                fontStyle="normal"
                                fontSize="12px"
                                color="white"
                                >Failed</CText
                              >
                            </CBox>
                          </CStack>
                        </CBox>
                        <CText
                          v-if="v2.duration && v2.duration > 0"
                          mt="2"
                          lineHeight="11px"
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="11px"
                          color="grey"
                        >
                          {{ formatAppResponse(v2.duration) }}ms
                        </CText>
                      </CBox>
                    </CBox>
                    <CText
                      v-if="v2.error && v2.error !== ''"
                      mt="2"
                      lineHeight="16px"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="12px"
                      color="red"
                    >
                      {{ v2.error }}
                    </CText>
                  </CBox>
                </CBox>
              </CBox>
              <CBox h="40px" bgColor="bgTestList" />
            </CBox>
            <CBox>
              <CBox h="20px" bgColor="bgTestList" />
              <CBox
                px="20px"
                bgColor="bgTestList"
                v-for="(v, i) in currentTest.result_test"
                :key="i"
              >
                <CBox
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor="borderTest"
                  bg="white"
                  w="100%"
                >
                  <CBox v-if="currentTest.result.assessment_result.evaluation">
                    <CBox
                      v-for="item in currentTest.result.assessment_result.evaluation"
                      :key="item.key"
                      px="20px"
                      py="10px"
                      borderBottomWidth="1px"
                      borderBottomColor="borderTest"
                    >
                      <CBox d="flex" flexDirection="column">
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="700"
                          color="fontMain"
                        >Hasil {{ item.key }} : {{ item.item }}</CText>
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="400"
                          color="fontMain"
                        >Alasan : {{ item.reason }}</CText>
                        <CBox w="10px" />
                      </CBox>
                    </CBox>
                  </CBox>
                  <CBox v-else>
                    <CBox
                      px="20px"
                      py="10px"
                      borderBottomWidth="1px"
                      borderBottomColor="borderTest"
                    >
                      <CBox d="flex" flexDirection="column">
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="700"
                          color="fontMain"
                        >Hasil 1 : Default</CText>
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="700"
                          color="fontMain"
                        >Alasan : Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi natus culpa deleniti alias accusantium ipsa consequatur et aperiam, magni similique voluptas. Velit nam, quibusdam corrupti facere itaque culpa ab beatae.</CText>
                        <CBox w="10px" />
                      </CBox>
                    </CBox>
                  </CBox>
                </CBox>
              </CBox>
              <CBox h="40px" bgColor="bgTestList" />
            </CBox>
          </CBox>
          <CBox
            v-if="
              !checkEmpObject(currentTest.result) &&
              currentAppType.view_type != 'RECRUITMENT' &&
              !currentTest.is_review_result
            "
            bg="white"
            :mt="['13px', '13px', '20px', '20px']"
            :mb="['0', '0', '40px', '40px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox px="20px" py="20px">
              <CSimpleGrid
                :columns="[1, 1, 2, 2]"
                :spacing="5"
                :textAlign="['center', 'center', 'left', 'left']"
              >
                <CBox>
                  <CImage
                    :src="require('@/assets/img/illustration-programming.png')"
                  />
                </CBox>
                <CBox>
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="20px"
                    fontWeight="700"
                    color="fontMain"
                    pb="10px"
                    >Ukur Kemampuan Programming Kamu Disini
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="400"
                    color="fontMain"
                    pb="10px"
                    >Kini Anda dapat mengasah kemampuan programming sesungguhnya
                    di Gethired Devcode
                  </CText>
                  <ButtonBlue @click.native="openBlog()" label="Explore" />
                </CBox>
              </CSimpleGrid>
            </CBox>
          </CBox>
        </CBox>
        <CBox
          v-if="!isLoading && currentAppType.view_type != 'RECRUITMENT'"
          pb="2em"
          min-w="100px"
          w="100%"
          max-w="100%"
        >
          <LeaderboardNew
            v-if="currentTest.app_type_id && !currentTest.is_review_result"
            :id="parseInt(currentTest.app_type_id)"
            :slug="currentAppType.slug"
            :default_leaderboard="currentAppType.default_leaderboard"
            :arena_id="currentTest.arena_id"
          />
          <LeaderboardFilters
            v-if="currentTest.app_type_id && !currentTest.is_review_result"
            :id="parseInt(currentTest.app_type_id)"
          />
          <Statistic v-if="currentTest.is_review_result" :id="parseInt(currentTest.app_type_id)"
                        :slug="currentAppType.slug" :default_leaderboard="currentAppType.default_leaderboard"
                        :arena_id="currentTest.arena_id"/>
        </CBox>
      </CGrid>
    </CBox>
    <CBox
      v-if="
        currentAppType.view_type != 'RECRUITMENT' &&
        currentTest.passed_score >= 70
      "
    >
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        filename="certificate"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a5"
        pdf-orientation="landscape"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasDownloaded="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <CBox mt="25px" mx="auto">
            <CertificateFitacademy
              v-if="currentAppType.certificate_type === 'fitacademy'"
              :showBorder="false"
            />
            <CertificateNiagahoster
              v-else-if="currentAppType.certificate_type === 'niagahoster'"
              :showBorder="false"
            />
            <CertificateCompetition
              v-else-if="currentAppType.certificate_type === 'uad'"
              :showBorder="false"
            />
            <Certificate v-else :showBorder="false" />
          </CBox>
        </section>
      </vue-html2pdf>
    </CBox>
    <ModalComments
      :isOpen="currentCommentModalStatus"
      :result_id="currentTest.id"
      :title="`Comments for ${currentTest.id}`"
      :comments="currentComments"
      :user_suggestions="currentUserSuggestions"
      :hashtag_suggestions="currentHashtagSuggestions"
      :type="'RESULT'"
    />
  </CBox>
</template>
<script>
import LeaderboardNew from "@/components/LeaderboardNew.vue";
import LeaderboardFilters from "@/components/LeaderboardFilters.vue";
import Certificate from "@/components/Certificate.vue";
import CertificateCompetition from "@/components/CertificateCompetition.vue";
import CertificateAspect from "@/components/CertificateAspect.vue";
import ButtonBlue from "@/components/button/ButtonBlue.vue";
import ButtonIcon from "@/components/button/ButtonIcon.vue";
import ModalComments from "@/components/modal/ModalComments.vue";
import { GET_TEST } from "@/store/test.module";
import { GET_APP_TYPE } from "@/store/app-types.module";
import { GET_LIKES, POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import {
  GET_COMMENTS,
  GET_USER_SUGGESTIONS,
  GET_HASHTAG_SUGGESTIONS,
  SET_TOGGLE_COMMENTS_MODAL,
} from "@/store/comment.module";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";
import config from "@/ConfigProvider";
import VueHtml2pdf from "vue-html2pdf";
import AppDescription from "@/components/AppDescription.vue";
import { GET_USER } from "@/store/auth.module";
import CertificateNiagahoster from "../../components/CertificateNiagahoster.vue";
import CertificateFitacademy from "../../components/CertificateFitacademy.vue";
import CertificateProfession from "../../components/CertificateProfession.vue";
import Statistic from "../../components/Statistic.vue";


export default {
  metaInfo() {
    return {
      title: this.$route.params.id,
      titleTemplate: "Devcode - %s",
      meta: [
        { name: "robots", content: "max-image-preview:large, index, follow" },
        { name: "googlebot", content: "index, follow, follow" },
        { name: "googlebot-news", content: "index, follow, follow" },
        { name: "description", content: this.passedInfo },
        { name: "image", content: this.metaImage },
        { name: "twitter:card", content: "summary_large_image" },
        {
          name: "twitter:title",
          content: `Devcode - ${this.$route.params.id} | ${this.currentAppType.name}`,
        },
        { name: "twitter:description", content: this.passedInfo },
        { name: "twitter:image", content: this.metaImage },
        {
          property: "og:site_name",
          content: `Devcode - ${this.$route.params.id} | ${this.currentAppType.name}`,
        },
        { property: "og:type", content: "article" },
        {
          property: "og:title",
          content: `Devcode - ${this.$route.params.id} | ${this.currentAppType.name}`,
        },
        { property: "og:locale", content: "id_ID" },
        { property: "og:description", content: this.passedInfo },
        { property: "og:image", content: this.metaImage },
      ],
    };
  },
  components: {
    ButtonBlue,
    ButtonIcon,
    Loading,
    VueHtml2pdf,
    ModalComments,
    AppDescription,
    LeaderboardNew,
    LeaderboardFilters,
    Certificate,
    CertificateAspect,
    CertificateCompetition,
    CertificateNiagahoster,
    CertificateFitacademy,
    CertificateProfession,
    Statistic
  },
  data() {
    return {
      refreshInterval: null,
      isLoading: false,
      baseUrl: config.value("DEVCODE_WEB_URL"),
      blogUrl: config.value("DEVCODE_BLOG_URL"),
      signinUrl: `${config.value("GETHIRED_WEB_URL")}/signin`,
      notPassedInformation:
        "Maaf aplikasi yang kamu submit tidak memenuhi <b>70%</b> dari test scenario.<br>Perhatikan kembali test scenario dan requirement aplikasi yang ada, untuk mendapatkan <b>Sertifikat</b> dari kami.",
      // showDownload: false,
      passedInfo: "",
      shareText: "",
      shareUrl: "",
      metaImage: "",
      tempResultLikes: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.autoLoad();
  },
  beforeDestroy: function () {
    // clear auto refresh interval
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentChart() {
      const categories = [];
      const values = [];
      const values_total = [];

      let totalvalue = 0;
      const groupTests = this.$store.getters.currentTest.result.detail;
      for (const groupTest of groupTests) {
        let totalgroup = 0;
        categories.push(groupTest.title);
        for (const currentResult of groupTest.tests) {
          // currentResult.duration = currentResult.duration.toFixed(2)
          if (currentResult.memory && currentResult.memory > 0) {
            totalgroup += currentResult.memory;
            totalvalue += currentResult.memory;
          }
        }
        values.push(totalgroup);
        values_total.push(totalvalue);
      }

      return {
        chartOptions: {
          chart: {
            id: "vuechart-example",
          },
          xaxis: {
            categories: categories,
            labels: {
              rotate: 180,
            },
          },
          colors: ["#F44E7C", "#FBBC04", "#49619C"],
          plotOptions: {
            bar: {
              columnWidth: "100%",
            },
          },
          stroke: {
            curve: "smooth",
            width: [3, 3, 1],
          },
          fill: {
            opacity: [1, 0.6, 0.2],
          },
        },
        series: [
          {
            name: "series-1",
            data: values_total,
            type: "line",
          },
          {
            name: "series-1",
            data: values,
            type: "bar",
          },
        ],
      };
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    currentLikes() {
      return this.$store.getters.currentResultLikes;
    },
    currentComments() {
      return this.$store.getters.currentComments;
    },
    currentCommentModalStatus() {
      return this.$store.getters.currentCommentModalStatus;
    },
    currentUserSuggestions() {
      return this.$store.getters.currentUserSuggestions;
    },
    currentHashtagSuggestions() {
      return this.$store.getters.currentHashtagSuggestions;
    },
    isLikedByUser() {
      return this.$store.getters.resultLikedByUser;
    },
    showDownloadAndDescription() {
      if (
        this.currentUser &&
        this.currentUser.emp_id == this.currentTest.emp_id
      )
        return true;
      return false;
    },
    showLogJob() {
      const isRecordingPublic =
        this.currentAppType?.settings?.is_recording_public ?? false;
      const cond = this.currentAppType?.type !== "LOGIC" || isRecordingPublic;
      return cond;
    },
  },
  methods: {
    displayHashtagsList(hashtags) {
      return hashtags.map((hashtag) => hashtag.name);
    },
    isUserResult() {
      return (
        this.currentUser && this.currentUser.emp_id === this.currentTest.emp_id
      );
    },
    isTeamMode() {
      return this.currentAppType?.settings?.mode === "team";
    },
    redirectToSignIn() {
      window.location.href = this.signinUrl;
    },
    getCookieByName(cookieName) {
      let cookie = {};
      document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName];
    },
    async postResultLike() {
      if (this.currentUser) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "result",
            id: this.$route.params.id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "result",
              id: this.$route.params.id,
            });
          }
        } finally {
          await this.$store.dispatch(GET_LIKES, {
            type: "result",
            id: this.$route.params.id,
          });
        }
      } else {
        this.redirectToSignIn();
      }
    },
    showCommentsModal() {
      this.$store.commit(SET_TOGGLE_COMMENTS_MODAL, true);
    },
    showFeedbackModal(isGotFeedback, expiredDate, slug) {
      if (isGotFeedback === "false") {
        Swal.fire({
          title:
            '<h4 style="font-size: 20px;">Form Feedback Devcode Challenge</h4>',
          html: `<iframe id="feedbackIframe" src="https://docs.google.com/forms/d/e/1FAIpQLScZhMDD2-SVIaNtBEBuF7tqk8mtS69b_G6aA1lUkOL4JoECAA/viewform?embedded=true&usp=pp_url&entry.34679941=${this.currentUser.emp_email}&entry.1197519896=${this.currentAppType.name}" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
          width: "900px",
          showCloseButton: true,
          showConfirmButton: false,
        });

        document.cookie = `got_feedback_${slug}=true; expires=${expiredDate}`;
      }
    },
    apexChartValues(metric) {
      const values = [
        {
          name: metric.metric,
          data: metric.value,
          // type: 'bar'
        },
      ];
      return values;
    },
    apexChartOptions(metric) {
      const options = {
        chart: {
          id: "vuechart-example",
          type: "bar",
          // height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: false,
            dataLabels: {
              orientation: "vertical",
              position: "bottom",
            },
          },
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],
        xaxis: {
          categories: metric.label,
          labels: {
            show: false,
          },
          // labels: {
          //   rotate: 90
          // }
        },
        // colors: ['#F44E7C','#FBBC04','#49619C'],
        // plotOptions: {
        //   bar: {
        //     columnWidth: '100%'
        //   }
        // },
        yaxis: {
          labels: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          position: "left",
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val) {
            return val;
            // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            // return opt.w.globals.labels[opt.dataPointIndex]
          },
          offsetX: 0,
          // offsetX: 100,
          // dropShadow: {
          //   enabled: true
          // }
        },
        // stroke: {
        //   curve: 'smooth',
        //   width: [3, 3, 1]
        // },
        // fill: {
        //   opacity: [1, 0.6, 0.2]
        // },
      };
      // console.log(options)
      return options;
    },
    autoLoad() {
      // auto refresh page every 5 seconds when the result still in progress
      let me = this;
      if (me.currentTest) {
        this.refreshInterval = setInterval(function () {
          if (
            (me.checkEmpObject(me.currentTest) ||
              !me.checkEmpObject(me.currentTest.result)) &&
            !me.currentTest?.is_evaluate
          ) {
            if (me.refreshInterval) {
              clearInterval(me.refreshInterval);
              me.refreshInterval = null;
            }
          } else {
            me.getData();
          }
        }, 5000);
      }
    },
    formatAppResponse(value) {
      if (value) {
        let val = (Math.round(value * 100) / 100).toFixed(2);
        return val.toString().replace(".00", "");
      }
      return null;
    },
    formatResponseTime(value) {
      if (value) {
        let val = (Math.round((value / 1000) * 100) / 100).toFixed(1);
        return val.toString().replace(".", ",").replace(",0", "");
      }
      return null;
    },
    openBlog() {
      window.open(this.blogUrl, "_blank");
    },
    openSite(url) {
      window.open(url, "_blank");
    },
    // autoload() {
    //   if (this.checkEmpObject(this.currentTest.result)) {
    //     this.getautoload = setInterval(() => {
    //       this.$store.dispatch(GET_TEST, this.$route.params.id);
    //     }, 10000);
    //   }
    // },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    async getData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(GET_TEST, this.$route.params.id);
        await this.$store.dispatch(GET_LIKES, {
          type: "result",
          id: this.$route.params.id,
        });
        this.$store.dispatch(GET_COMMENTS, { id: this.$route.params.id });
        this.$store.dispatch(GET_USER_SUGGESTIONS, {
          id: this.$route.params.id,
        });
        this.$store.dispatch(GET_HASHTAG_SUGGESTIONS, {
          id: this.$route.params.id,
        });
        Promise.allSettled([
          this.$store.dispatch(GET_APP_TYPE, this.currentTest.app_type_id),
          this.$store.dispatch(GET_USER),
        ]);
        // this.autoload();
        this.isLoading = false;
        // console.log("==>", this.currentAppType.settings.mode);
      } catch (err) {
        // console.log(err);
        // this.isLoading = false;
        // if (err.code === 401) {
        //   Swal.fire({
        //     text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id",
        //     heightAuto: true,
        //     willClose: () => {
        //       window.location.href =
        //         config.value("GETHIRED_WEB_URL") + "/employee/profile";
        //     },
        //   });
        // }
      }
      this.shareUrl = this.baseUrl + "/job/share/" + this.$route.params.id;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress() {
      // this.isLoading = true;
    },
    hasGenerated() {
      // this.isLoading = false;
    },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    updateShareContent() {
      this.passedInfo = `Saya berhasil lulus ${
        this.currentTest.passed_score
      }% dari skenario yang ada pada Devcode ${
        this.currentAppType.name
      } dengan Response Time ${this.formatResponseTime(
        this.currentTest.total_duration
      )}s. Tantang teman kamu, apakah dia bisa menyelesaikan coding test ini ?`;
      this.shareText = `Saya berhasil lulus ${
        this.currentTest.passed_score
      }% dari skenario yang ada pada Devcode ${
        this.currentAppType.name
      } dengan Response Time ${this.formatResponseTime(
        this.currentTest.total_duration
      )}s.`;
      this.metaImage =
        config.value("DEVCODE_RENDER_URL") + "/cert/" + this.$route.params.id;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
    currentUser: {
      deep: true,
      handler() {
        if (
          this.currentTest.job_status === "DONE" &&
          this.currentUser.emp_id === this.currentTest.emp_id
        ) {
          let response = this.getCookieByName(
            `got_feedback_${this.currentAppType.slug}`
          );

          let gotFeedbackChallenge = response ? response : "false";
          let expiredDate = moment().add(3, "days");

          this.showFeedbackModal(
            gotFeedbackChallenge,
            expiredDate,
            this.currentAppType.slug
          );
        }
      },
    },
    currentTest: function () {
      this.updateShareContent();
    },
    currentAppType: function () {
      this.updateShareContent();
    },
  },
  // destroyed() {
  //   clearInterval(this.getautoload);
  // },
};
</script>
