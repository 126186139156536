<template>
  <CBox v-if="currentAppType.type !== 'LOGIC'">
    <CBox
      bgImage="url('/img/bg-certificate-niagahoster.png')"
      bgSize="100% 480px"
      bgRepeat="no-repeat"
      minH="480px"
      mx="auto"
      :d="['none', 'none', 'block', 'block']"
    >
      <CGrid templateColumns="3fr 1fr" :gap="4" w="100%">
        <CBox textAlign="center">
          <CBox h="350px">
            <CText
              ml="62px"
              pt="40px"
              fontFamily="Poppins"
              fontStyle="normal"
              textAlign="left"
              color="white"
              fontWeight="bold"
              fontSize="28px"
              >Sertifikat WordPress Championship</CText
            >
          </CBox>
          <CBox mt="16px" align="center" pr="60px">
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontWeight="bold"
              fontSize="10px"
              >ID {{ currentTest.id }}</CText
            >
            <VueQr
              backgroundColor="#F4F4F4"
              :text="baseUrl + '/job/' + currentTest.id"
              :size="70"
              :margin="0"
            />
          </CBox>
        </CBox>
        <CBox textAlign="left">
          <CBox
            bgImage="url('/img/niagahoster-logo.png')"
            color="fontMain"
            bgSize="80% 55px"
            bgRepeat="no-repeat"
            minH="55px"
            mt="40px"
            mx="auto"
            :d="['none', 'none', 'block', 'block']"
          ></CBox>
          <CText
            mt="5px"
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="bolder"
            color="fontMain"
            >Sertifikat diberikan kepada</CText
          >
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="700"
            :fontSize="isSmallerFontSize ? '21px' : '32px'"
            verticalAlign="middle"
            >{{ currentTest.user.emp_full_name }}</CText
          >
          <CText
            mt="2px"
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="12px"
            color="fontMain"
            >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
            mengikuti<br />WordPress Championship 2023 dari Niagahoster dan Devcode.</CText
          >
          <CBox align="center" w="400px" pr="56px">
            <CText
              mb="2px"
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="24px"
              color="#29AAFD"
              fontWeight="bold"
              >LULUS</CText
            >
          </CBox>
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="12px"
            color="fontMain"
            >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang
            ada dengan detail :</CText
          >
          <CBox mt="2px">
            <CGrid templateColumns="1fr 3fr" :gap="3" w="350px">
              <CBox
                w="100%"
                h="100%"
                :border="showBorder ? '1px' : '0'"
                borderRadius="6px"
                align="center"
                borderColor="bgMain"
              >
                <CText
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="11px"
                  color="fontMain"
                  fontWeight="bolder"
                  pt="10px"
                  lineHeight="10px"
                  >RESPONSE TIME</CText
                >
                <CText
                  pb="10px"
                  pt="10px"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="32px"
                  fontWeight="bolder"
                  color="#29AAFD"
                  lineHeight="31px"
                  >{{ formatResponseTime(currentTest.total_duration) }}s</CText
                >
              </CBox>
              <CBox>
                <CBox
                  w="100%"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  align="center"
                  borderColor="bgMain"
                >
                  <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                    <CText
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontSize="12px"
                      color="fontMain"
                      fontWeight="bolder"
                      >PERINGKAT {{ currentTest.rank }}</CText
                    >
                    <CText
                      pl="4px"
                      pt="2px"
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontSize="10px"
                      color="fontMain"
                      fontWeight="300"
                      >/
                      {{ currentTest.total_participants }}
                      peserta</CText
                    >
                  </CBox>
                </CBox>
                <CBox pt="5px">
                  <CSimpleGrid :columns="2" :spacing="2">
                    <CBox
                      d="flex"
                      :border="showBorder ? '1px' : '0'"
                      borderRadius="6px"
                      borderColor="bgMain"
                      w="100%"
                      h="38px"
                    >
                      <CBox mx="auto" h="inherit">
                        <CStack
                          d="table-cell"
                          direction="row"
                          h="inherit"
                          verticalAlign="middle"
                        >
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="14px"
                              color="fontMain"
                              fontWeight="bolder"
                              >PASSED</CText
                            >
                          </CBox>
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            pl="10px"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="22px"
                              color="passedColor"
                              fontWeight="bolder"
                              >{{ currentTest.result.passed }}</CText
                            >
                          </CBox>
                        </CStack>
                      </CBox>
                    </CBox>
                    <CBox
                      d="flex"
                      :border="showBorder ? '1px' : '0'"
                      borderRadius="6px"
                      borderColor="bgMain"
                      w="100%"
                      h="38px"
                    >
                      <CBox mx="auto" h="inherit">
                        <CStack
                          d="table-cell"
                          direction="row"
                          h="inherit"
                          verticalAlign="middle"
                        >
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="14px"
                              color="fontMain"
                              fontWeight="bolder"
                              >SCORE</CText
                            >
                          </CBox>
                          <CBox
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            pl="10px"
                          >
                            <CText
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              fontFamily="Poppins"
                              fontStyle="normal"
                              fontSize="22px"
                              color="passedColor"
                              fontWeight="bolder"
                              >{{ currentTest.total_point }}</CText
                            >
                          </CBox>
                        </CStack>
                      </CBox>
                    </CBox>
                  </CSimpleGrid>
                </CBox>
              </CBox>
            </CGrid>
          </CBox>
          <CBox h="10px" />
          <CBox d="flex" justify="between" :gap="4">
            <CBox w="165px" align="center">
              <CImage 
                h="40px"
                w="80px"
                mx="auto"
              />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="12px"
                color="fontMain"
                fontWeight="800"
                >Rizqinofa Putra M</CText
              >
              <CDivider borderColor="borderBox" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="10px"
                color="fontMain"
                fontWeight="bolder"
                >Devcode Challenge Committee</CText
              >
            </CBox>
            <CBox ml="12px" w="165px" align="center">
              <CBox h="40px" w="80px" mx="auto" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="12px"
                color="fontMain"
                fontWeight="800"
                >Ade Lubis</CText
              >
              <CDivider borderColor="borderBox" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="10px"
                color="fontMain"
                fontWeight="bolder"
                >CEO Niagahoster</CText
              >
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
    </CBox>

    <!-- mobile layout -->
    <CBox
      :d="['block', 'block', 'none', 'none']"
      border="1px solid"
      borderColor="bgMain"
      p="1em"
      borderRadius="5px"
      align="center"
    >
      <CText
        mt="10px"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="bolder"
        color="fontMain"
        >Sertifikat Programming Competition<br />ICOM 2022 x GetHired</CText
      >
      <CText
        mt="20px"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="14px"
        fontWeight="bolder"
        color="fontMain"
        >Sertifikat diberikan kepada</CText
      >
      <CText
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="20px"
        fontWeight="bolder"
        color="#D9019C"
        d="table-cell"
        verticalAlign="middle"
        >{{ currentTest.user.emp_full_name }}</CText
      >
      <CText
        whiteSpace="wrap"
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="12px"
        color="fontMain"
        >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
        mengikuti<br />ICOM 2022 dengan tema {{ currentAppType.name }}.</CText
      >
      <CBox align="center">
        <CText
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="30px"
          fontWeight="bold"
          color="fontMain"
          py="0.5em"
          >LULUS</CText
        >
      </CBox>
      <CText
        fontFamily="Poppins"
        fontStyle="normal"
        fontSize="12px"
        color="fontMain"
        >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang ada
        dengan detail :</CText
      >
      <CBox mt="5px">
        <CGrid templateColumns="12fr" :gap="3">
          <CBox
            w="100%"
            h="100%"
            :border="showBorder ? '1px' : '0'"
            borderRadius="6px"
            align="center"
            borderColor="bgMain"
          >
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="11px"
              color="fontMain"
              fontWeight="bolder"
              pt="10px"
              lineHeight="10px"
              >RESPONSE TIME</CText
            >
            <CText
              pb="10px"
              pt="10px"
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="32px"
              color="#D9019C"
              fontWeight="bolder"
              lineHeight="31px"
              >{{ formatResponseTime(currentTest.total_duration) }}s</CText
            >
          </CBox>
          <CBox>
            <CBox
              w="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                <CText
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="12px"
                  color="fontMain"
                  fontWeight="bolder"
                  >PERINGKAT {{ currentTest.rank }}</CText
                >
                <CText
                  pl="4px"
                  pt="2px"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontSize="10px"
                  color="fontMain"
                  fontWeight="300"
                  >dari
                  {{ currentTest.total_participants }}
                  peserta</CText
                >
              </CBox>
            </CBox>
            <CBox pt="5px">
              <CSimpleGrid :columns="2" :spacing="2">
                <CBox
                  d="flex"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  borderColor="bgMain"
                  w="100%"
                  h="38px"
                >
                  <CBox mx="auto" h="inherit">
                    <CStack
                      d="table-cell"
                      direction="row"
                      h="inherit"
                      verticalAlign="middle"
                    >
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="14px"
                          color="fontMain"
                          fontWeight="bolder"
                          >PASSED</CText
                        >
                      </CBox>
                      <CBox
                        d="table-cell"
                        verticalAlign="middle"
                        h="inherit"
                        pl="10px"
                      >
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="22px"
                          color="passedColor"
                          fontWeight="bolder"
                          >{{ currentTest.result.passed }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CBox>
                </CBox>
                <CBox
                  d="flex"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  borderColor="bgMain"
                  w="100%"
                  h="38px"
                >
                  <CBox mx="auto" h="inherit">
                    <CStack
                      d="table-cell"
                      direction="row"
                      h="inherit"
                      verticalAlign="middle"
                    >
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="14px"
                          color="fontMain"
                          fontWeight="bolder"
                          >SCORE</CText
                        >
                      </CBox>
                      <CBox
                        d="table-cell"
                        verticalAlign="middle"
                        h="inherit"
                        pl="10px"
                      >
                        <CText
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          fontFamily="Poppins"
                          fontStyle="normal"
                          fontSize="22px"
                          color="passedColor"
                          fontWeight="bolder"
                          >{{ currentTest.total_point }}</CText
                        >
                      </CBox>
                    </CStack>
                  </CBox>
                </CBox>
              </CSimpleGrid>
            </CBox>
          </CBox>
        </CGrid>
      </CBox>
    </CBox>
  </CBox>
</template>
<script>
import config from "@/ConfigProvider";
import { CText } from "@chakra-ui/vue";
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
    CText,
  },
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      isSmallerFontSize: false,
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
  },
  mounted() {
    this.formatNameFontSize();
  },
  methods: {
    formatResponseTime(value) {
      if (value) {
        let val = (Math.round((value / 1000) * 100) / 100).toFixed(1);
        return val.toString().replace(".", ",").replace(",0", "");
      }
      return null;
    },
    formatNameFontSize() {
      if (this.currentTest.user.emp_full_name.length > 20) {
        this.isSmallerFontSize = true;
      } else {
        this.isSmallerFontSize = false;
      }
    },
  },
};
</script>
