<template>
  <CBox v-if="currentAppType.type !== 'LOGIC'">
    <CBox w="100%" py="6vh" v-if="aspects.length > 0">
      <CBox mt="3vh" mb="6vh" align="center">
        <CBox mb="18px" mt="4vh">
          <CBox textAlign="left" ml="13vw" mb="6">
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="26px"
              fontWeight="bolder"
              color="activeMenu"
              d="table-cell"
              verticalAlign="middle"
              >{{ currentTest.user.emp_full_name }}</CText
            >
          </CBox>
          <CGrid templateColumns="1fr 1fr 1fr 1fr" :gap="3" w="65%">
            <CBox
              w="100%"
              h="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="11px"
                color="fontMain"
                fontWeight="bolder"
                pt="10px"
                lineHeight="10px"
                >SCORE</CText
              >
              <CText
                pb="10px"
                pt="10px"
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="32px"
                color="activeMenu"
                fontWeight="bolder"
                lineHeight="31px"
                >{{ currentTest.total_point }}</CText
              >
            </CBox>
            <CBox
              w="100%"
              h="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="11px"
                color="fontMain"
                fontWeight="bolder"
                pt="10px"
                lineHeight="10px"
                >RESPONSE TIME</CText
              >
              <CText
                pb="10px"
                pt="10px"
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="32px"
                color="activeMenu"
                fontWeight="bolder"
                lineHeight="31px"
                >{{ formatResponseTime(currentTest.total_duration) }}s</CText
              >
            </CBox>
            <CBox
              w="100%"
              h="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="11px"
                color="fontMain"
                fontWeight="bolder"
                pt="10px"
                lineHeight="10px"
                >PASSED</CText
              >
              <CText
                pb="10px"
                pt="10px"
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="32px"
                color="passedColor"
                fontWeight="bolder"
                lineHeight="31px"
                >{{ currentTest.result.passed }}</CText
              >
            </CBox>
            <CBox
              w="100%"
              h="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="11px"
                color="fontMain"
                fontWeight="bolder"
                pt="10px"
                lineHeight="10px"
                >FAILED</CText
              >
              <CText
                pb="10px"
                pt="10px"
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="32px"
                color="failedColor"
                fontWeight="bolder"
                lineHeight="31px"
                >{{ currentTest.result.failed }}</CText
              >
            </CBox>
          </CGrid>
        </CBox>
        <CStack
          borderTop="1px"
          borderColor="bgMain"
          spacing="3"
          w="65%"
          pt="18px"
        >
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="12px"
            fontWeight="700"
            align="left"
          >
            ASPECT
          </CText>
          <table width="100%" cellpadding="4">
            <tbody>
              <tr v-for="(r, i) in aspects" :key="i">
                <td width="25%">
                  <CText
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ r.name }}
                  </CText>
                </td>
                <td width="65%">
                  <CProgress size="md" :value="r.value" :color="r.color" />
                </td>
                <td width="5%">
                  <CText
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="12px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ r.value }}%
                  </CText>
                </td>
              </tr>
            </tbody>
          </table>
        </CStack>
      </CBox>
    </CBox>
  </CBox>
</template>
<script>
import config from "@/ConfigProvider";

export default {
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      aspects: [],
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
  },
  watch: {
    currentTest(current) {
      if (current?.result?.aspectSummary) this.fetchSummary();
    },
  },
  mounted() {
    this.fetchSummary();
  },
  methods: {
    fetchSummary() {
      const summary = this.currentTest.result.aspectSummary;
      let aspectSummary = [];
      for (let idx in summary) {
        aspectSummary.push({
          name: idx,
          value: Math.round((summary[idx].pass / summary[idx].total) * 100),
          color: "green",
        });
      }
      this.aspects = aspectSummary;
    },
    formatResponseTime(value) {
      if (value) {
        let val = (Math.round((value / 1000) * 100) / 100).toFixed(1);
        return val.toString().replace(".", ",").replace(",0", "");
      }
      return null;
    },
  },
};
</script>
